<template>
  <a ref="telButton" v-show="false" :href="`mailto:${$route.params.mailto}`" />
</template>
<script>
export default {
  mounted() {
    this.$refs.telButton.click();
    this.$router.back();
  },
};
</script>
